<template>
	<div>
		<div class="query" style="text-align: left;margin-left: 25px;height: 50px;">
			<div>认证状态：
				<el-select class="select" v-model="state" placeholder="请选择认证状态" @change="realauthList(1)" clearable>
					<el-option v-for="item in stateList" :key="item.value" :label="item.label" :value="item.value">
					</el-option>
				</el-select>
			</div>
		</div>
		<el-table :data="realauthData" style="width: 100%" v-loading="loading">
			<el-table-column prop="id" label="实名认证ID" align="center" width="120"></el-table-column>
			<el-table-column prop="authname" label="姓名" align="center"></el-table-column>
			<el-table-column prop="authnumber" label="身份证号" align="center"></el-table-column>
			<el-table-column prop="authphotoupper" label="身份证照片(正面)" align="center">
				<template slot-scope="scope">
					<el-image style="width: 160px; height: 90px" alt="身份证照片(正面)" :src="scope.row.authphotoupper"
						:preview-src-list="[scope.row.authphotoupper]"></el-image>
				</template>
			</el-table-column>
			<el-table-column prop="authphotobelow" label="身份证照片(背面)" align="center">
				<template slot-scope="scope">
					<el-image style="width: 160px; height: 90px" alt="身份证照片(背面)" :src="scope.row.authphotobelow"
						:preview-src-list="[scope.row.authphotobelow]"></el-image>
				</template>
			</el-table-column>
			<el-table-column prop="createtime" label="申请时间" align="center"></el-table-column>
			<el-table-column prop="statusName" label="实名认证状态" align="center"></el-table-column>
			<el-table-column label="审核" align="center" width="100">
				<template slot-scope="scope">
					<el-button type="primary" :disabled="scope.row.statusName != '已申请'" @click="audit(scope.row.id)">审核
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="block">
			<el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
				layout="total, prev, pager, next, jumper" :total="CountNum" style="margin-top: 20px">
			</el-pagination>
		</div>
		<el-dialog title="审核" :visible.sync="dialogFormVisible" width="400px" center>
			<span>备注：</span>
			<el-input v-model="remark" style="width: 300px;"></el-input>
			<template slot="footer">
				<el-button type="success" @click="submit(2)">通过</el-button>
				<el-button type="danger" @click="submit(3)">拒绝</el-button>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		realauthList, //实名认证列表接口
		realauth //实名认证审核接口
	} from '@/api/adminApi.js'
	export default {
		data() {
			return {
				realauthData: [], // 实名认证列表
				stateList: [{ //认证状态筛选
					label: '已申请',
					value: 1
				}, {
					label: '已通过',
					value: 2
				}, {
					label: '已拒绝',
					value: 3
				}, {
					label: '已失效',
					value: 4
				}],
				state: null, //选择的筛选条件
				loading: false, //加载框
				remark: '', //提交审核的备注信息
				statusId: null, //提交审核的认证id
				dialogFormVisible: false, //弹窗
				currpage: 1, // 初始页
				pagesize: 10, // 每页显示条目个数
				CountNum: 0 // 总条数
			}
		},
		mounted() {
			this.realauthList(1); // 获取第一页实名认证列表
		},
		methods: {
			audit(id) { //审核按钮
				this.statusId = id; //审核人id
				this.remark = '';	//清空备注
				this.dialogFormVisible = true; //打开弹窗
			},
			async realauthList(currpage) { // 实名认证列表
				this.loading = true;
				const data = {
					page: currpage, // 当前页数
					limit: this.pagesize, // 每页条数
					status: this.state ? this.state : 100 //筛选申诉状态（1已申请 2已通过 3已拒绝 4已失效 默认全部）
				}
				let res = await realauthList(data); //请求接口
				this.realauthData = res.data.List; // 实名认证列表
				this.CountNum = parseInt(res.data.CountNum); // 总条数
				this.loading = false;
			},
			async submit(status) { //审核通过拒绝按钮
				const data = {
					id: this.statusId, //申诉id
					status: status, //申诉审核结果( 2通过 3拒绝 )
					remark: this.remark //申诉的备注信息
				};
				let res = await realauth(data); //请求接口
				if (res.status.code == 1) {
					this.$message.success("审核成功");
					this.realauthList(1); // 获取第一页实名认证列表
				} else {
					this.$message.error(res.status.msg);
				}
				this.dialogFormVisible = false; //关闭弹窗
			},
			handleCurrentChange(val) { // 分页
				this.realauthList(val);
				this.currpage = val;
			}
		}
	}
</script>

<style>
	.el-popconfirm__main {
		display: none;
	}
</style>
